import { AutocompleteSource } from "@algolia/autocomplete-js";
import { BaseItem } from "@algolia/autocomplete-core";
import { SearchClient } from "algoliasearch/lite";
import React from "react";

export default abstract class AutocompleteConfig<T extends BaseItem> {
  indexName: string;

  protected constructor(indexName: string) {
    this.indexName = indexName;
  }

  abstract readonly emptyQueryCollectionId: string;
  abstract readonly emptyQueryCollectionTitle: string;
  abstract readonly queryCollectionId: string;

  abstract filterString(): string;

  abstract emptyQueryResults(): AutocompleteSource<T> | boolean | undefined;

  abstract queryResults(searchClient: SearchClient): AutocompleteSource<T> | boolean | undefined;

  abstract searchResultsPath(query: string): string | undefined;

  abstract itemComponent(item: T, index: number): React.JSX.Element;
}
